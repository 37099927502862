import {
  doorpost,
  acistant,
  asankar,
  deliro,
  geenstress,
  IProject,
  onro,
  pickup,
  sanic,
  hellocourier,
  emrazgo,
  oto,
  vee,
  catringo,
  hio,
  loco,
  annanow,
  askeriglobal,
  dlxapp,
  dmoapp,
  fabbud,
  munchi,
  pickngo,
  quicksend,
  beswifty,
  strikeafrica,
  superkiwilogistics,
  swiftee,
  vroomvroom,
  wadelivery,
  weedeliverapp,
  zoomcurier,
  kwikdash,
  rybitCityspider,
  jrun,
  sprint,
  flech,
  aofferdelivery,
  caseworld,
  carrecovery911,
  elatreqi,
  rappid,
  getitpicked,
  floti,
  famvalet,
  automater,
  swiftitexpress,
  kerryexpress,
  nwacourier,
  flyto,
  bristolcountycouriers,
  kerryexpresstest,
  clarabiofuels,
  scooty,
  nobodi,
  gripx,
  bigticit,
  twentyFourCouriers,
  jenatrucks,
  geshikt,
  cryogenicsgroup,
  skiptheq,
  parcelbeep,
  maydaycourier,
  pinkexpress,
  reflexcourier,
  snapbox,
  anypoint,
  Itsthetruckingforme,
  privitridesdelivery,
  beyonddelivery,
  mannaev,
  landeber,
  joradelivery,
  gopanda,
  couriersexpress,
  swishr,
  kiosken,
  fasttrack,
  ondelivery,
  kwikr,
  loadmanexpress,
  eaglepost,
  oneThousandSolutions,
  pickndrop,
  ezdelivery,
  gettydispatch,
  deliveryondemand,
  blink,
  ThreeDegreesDelivery,
  Aurel,
  jpcarrier
} from "@app/config/projects";

export const getSetting = (): IProject => {
  const hostname = window.location.hostname;
  if (hostname === "tr.onro.io") {
    return onro;
  } else if (hostname === "tr.onro.org") {
    return onro;
  } else if (hostname === "track.pickup.global") {
    return pickup;
  } else if (hostname === "tr.acistant.xyz") {
    return acistant;
  } else if (hostname === "track.asankar.co") {
    return asankar;
  } else if (hostname === "tr.deliro.ir") {
    return deliro;
  } else if (hostname === "track.doorpost.com.au") {
    return doorpost;
  } else if (hostname === "tr.hellocourier.co.ke") {
    return hellocourier;
  } else if (hostname === "tr.emrazgo.com") {
    return emrazgo;
  } else if (hostname === "tr.otokh.com") {
    return oto;
  } else if (hostname === "nowtr.veedelivery.com") {
    return vee;
  } else if (hostname === "track.catrin-go.com") {
    return catringo;
  } else if (hostname === "tr.loco.direct") {
    return loco;
  } else if (hostname === "tr.hio.ro") {
    return hio;
  } else if (hostname === "tr.fab.com.np") {
    return fabbud;
  } else if (hostname === "tr.geenstress.amsterdam") {
    return geenstress;
  } else if (hostname === "tr.pickngosg.com") {
    return pickngo;
  } else if (hostname === "trackmy.wadelivery.com") {
    return wadelivery;
  } else if (hostname === "tr.curier-local.ro") {
    return zoomcurier;
  } else if (hostname === "track.swiftee.co.uk") {
    return swiftee;
  } else if (hostname === "tr.saniclogistics.com") {
    return sanic;
  } else if (hostname === "live.gomunchi.com") {
    return munchi;
  } else if (hostname === "tr.quicksend.ug") {
    return quicksend;
  } else if (hostname === "tr.beswifty.co.uk") {
    return beswifty;
  } else if (hostname === "tr.vroomvroom.ph") {
    return vroomvroom;
  } else if (hostname === "tr.askeriglobal.com") {
    return askeriglobal;
  } else if (hostname === "tr.dmoapp.in") {
    return dmoapp;
  } else if (hostname === "track.dlxapp.online") {
    return dlxapp;
  } else if (hostname === "track.strikeapp.link") {
    return strikeafrica;
  } else if (hostname === "tr.sklogistics.do") {
    return superkiwilogistics;
  } else if (hostname === "tracking.anna-now.com") {
    return annanow;
  } else if (hostname === "tracking.stage.anna-now.com") {
    return annanow;
  } else if (hostname === "tr.weedeliverapp.com") {
    return weedeliverapp;
  } else if (hostname === "tr.kwikdash.co") {
    return kwikdash;
  } else if (hostname === "tr.rybit-cityspider.com") {
    return rybitCityspider;
  } else if (hostname === "tr.j-run.az") {
    return jrun;
  } else if (hostname === "tr.sprint.xyz") {
    return sprint;
  } else if (hostname === 'tr.flech.io') {
    return flech
  } else if (hostname === 'tr.aofferonline.com') {
    return aofferdelivery
  } else if (hostname === 'tr.caseworldweb.com') {
    return caseworld
  } else if (hostname == 'tr.aladden.org') {
    return carrecovery911
  } else if (hostname == 'tr.3latreqi.com') {
    return elatreqi;
  } else if (hostname == 'track.tryrappid.com') {
    return rappid;
  } else if (hostname == 'tr.getitpicked.com') {
    return getitpicked;
  } else if (hostname == 'track.floti.io') {
    return floti;
  } else if (hostname == 'track.famvalet.com') {
    return famvalet;
  } else if (hostname == 'tr.autowmater.com') {
    return automater;
  } else if (hostname == 'track.swiftitexpress.com') {
    return swiftitexpress;
  } if (hostname == 'track.kerryexpress.ph') {
    return kerryexpress;
  } else if (hostname == 'track.nwacourier.com') {
    return nwacourier;
  } else if (hostname == 'track.flyto.fi') {
    return flyto;
  } else if (hostname == 'track.bristolcountycouriers.com') {
    return bristolcountycouriers;
  } else if (hostname == 'track.automoto.ph') {
    return kerryexpresstest;
  } else if (hostname == 'tr.clarabiofuels.com') {
    return clarabiofuels;
  } else if (hostname == 'track.scooty.gr') {
    return scooty;
  } else if (hostname == 'track.nobodi.com') {
    return nobodi;
  } else if (hostname == 'track.gripx.ph') {
    return gripx;
  } else if (hostname == 'track.bigtic-it.com') {
    return bigticit;
  } else if (hostname == 'track.24couriers.co.uk') {
    return twentyFourCouriers;
  } else if (hostname == 'track.jenatrucks.com') {
    return jenatrucks;
  } else if (hostname == 'track.geshikt.be') {
    return geshikt;
  } else if (hostname == 'track.thecryogroup.com.au') {
    return cryogenicsgroup;
  }  else if (hostname == 'track.skiptheq.com') {
    return skiptheq;
  } else if (hostname == 'tr.parcelbeep.com') {
    return parcelbeep;
  } else if (hostname == 'track.maydaycourier.com') {
    return maydaycourier;
  } else if (hostname == 'track.pink.express') {
    return pinkexpress;
  } else if (hostname == 'track.reflexcourier.ca') {
    return reflexcourier;
  } else if (hostname == 'track.snapboxjo.com') {
    return snapbox;
  } else if (hostname == 'track.anypoint.live') {
    return anypoint;
  } else if (hostname == 'track.privitrides.com') {
    return privitridesdelivery;
  } else if (hostname == 'track.thetruckingforme.com') {
    return Itsthetruckingforme;
  } else if (hostname == 'track.beyond-delivery.com') {
    return beyonddelivery;
  } else if (hostname == 'track.mannaev.com') {
    return mannaev;
  } else if (hostname == 'track.landeber.com') {
    return landeber;
  }  else if (hostname == 'track.joradelivery.com') {
    return joradelivery;
  } else if (hostname == 'track.gopanda.uk') {
    return gopanda;
  } else if (hostname == 'track.couriersexpress.com') {
    return couriersexpress;
  } else if (hostname == 'track.swishrcourier.com') {
    return swishr;
  } else if (hostname == 'tr.kiosken.site') {
    return kiosken;
  } else if (hostname == 'track.fastrack.lu') {
    return fasttrack;
  } else if (hostname == 'tr.ondelivery.us') {
    return ondelivery;
  } else if (hostname == 'track.kwikr.com') {
    return kwikr;
  } else if (hostname == 'track.loadmanexpress.com') {
    return loadmanexpress;
  } else if (hostname == 'track.eaglepost.com') {
    return eaglepost;
  } else if (hostname == 'track.1000solutions.ae') {
    return oneThousandSolutions;
  } else if (hostname == 'track.pickndrop.io') {
    return pickndrop;
  } else if (hostname == 'track.ezdeliveryservices.com') {
    return ezdelivery;
  } else if (hostname == 'track.gettico.com') {
    return gettydispatch;
  } else if (hostname == 'track.deliveryondemand.co') {
    return deliveryondemand;
  } else if (hostname == 'track.blinkds.com') {
    return blink;
  } else if (hostname == 'track.odos.tech') {
    return ThreeDegreesDelivery;
  } else if (hostname == 'tr.aurelapp.com') {
    return Aurel;
  } else if (hostname == 'tr.jpcarrier.ca') {
    return jpcarrier;
  } else {
    return onro;
  }
  
};
